
/*
[10:40, 17.12.2022] Christoph Burchert: Wichtige Info: "The CTG recordings start no more than 90 minutes before actual delivery, 
and each is at most 90 minutes long. Each CTG contains a fetal heart rate (FHR) time series and a uterine contraction (UC) signal, 
each sampled at 4 Hz."
[10:40, 17.12.2022] Christoph Burchert: 4 Hz, sprich 4/s, sprich Datenpunktabstand sind 250 ms
[10:40, 17.12.2022] Christoph Burchert: Vom Format her: W�re dir FHR + UC auf 250ms-Items als Array recht?
*/
/*
    Basalfrequenz: mittlere fetale Herzfrequenz während der Wehenpausen (Normwerte: 120-160 Schäge/Min.)
    Oszillationen: Schwankungen der basalen fetalen Herzfrequenz (Normwerte: 10-30 Schläge/Min.)
    Akzelerationen: Beschleunigungen der fetalen Herzfrequenz (normal sind vereinzelte Beschleunigungen um 10-15 Schläge/min. für 10-30 Sek.)
    Dezelerationen: Abfälle der fetalen Herzfrequenz (normalerweise treten keine Dezelerationen auf)
    Wehenfrequenz: Häufigkeit der Wehen (Normwerte: 1-3 Wehen/10 Min. während der Eröffnungsphase, 3-4 Wehen /10 Min. während der Austreibungsphase)
*/
import { IonContent, IonHeader, IonPage, IonToolbar, IonFooter, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/vue';
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel, toastController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';

import { io } from 'socket.io-client';

import moment from 'moment';

import {
  batteryFullOutline,
  settingsOutline,
  volumeHighOutline,
  volumeLowOutline,
  powerOutline,
  notificationsOutline,
  notificationsOffOutline,
  swapHorizontalOutline,
  playCircleOutline,
  stopCircleOutline,
  closeCircleOutline,
  codeDownloadOutline,
  //pulseOutline
} from 'ionicons/icons';


import VolumeSlider from './components/VolumeSlider.vue';

import SoundManager from '../services/SoundManager';

// use scenario store
import { useScenarioStore } from '@/stores/scenario'
import { DatabaseService } from '@/services/DatabaseService'

const baselineOffset = ref(0);

let ctgGraphData :any[] = [];
let ctgBaseFHRGraphData :any[] = [];
let ctgFHRNextGraphData :any[] = [];

let ctgBaseUCData :any[] = [];
let ctgUCNextData :any[] = [];


export default defineComponent({
  name: 'HomePage',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonFooter, IonGrid, IonRow, IonCol,
    IonIcon,
    IonAccordion, IonAccordionGroup, IonItem, IonLabel,
    VolumeSlider
  },
  setup() {
    // randomize the baseline offset between 10 and 80
    baselineOffset.value = Math.floor(Math.random() * (80 - 10 + 1)) + 10;

    console.log('baselineOffset', baselineOffset.value);


    return {
      batteryFullOutline,
      settingsOutline,
      volumeHighOutline,
      volumeLowOutline,
      powerOutline,
      notificationsOutline,
      notificationsOffOutline,
      swapHorizontalOutline,
      playCircleOutline,
      stopCircleOutline,
      closeCircleOutline,
      codeDownloadOutline,
      //pulseOutline
    };
  },
  data() {
    return {
    	dateString: '15.12.2022',
    	timeString: '00:00',
      fhr: 0,
      toco: 0,
      upperCanvas: HTMLCanvasElement.prototype,
      uppergraphCanvasGrid: HTMLCanvasElement.prototype,
      lowerCanvas: HTMLCanvasElement.prototype,
      lowergraphCanvasGrid: HTMLCanvasElement.prototype,
      fr1: 0,
      boxSize: 0,
      blockSize: 0,
      blockUCSize: 0,
      drawSize: 0,

      walkingGraphIDX: -1,
      walkingGraphDatapointer: 0,
      walkingGraphDatapointerUC: 0,
      
      
      demoIsPlaying: false,
      demoIDX: -1,
      fHeartbeatIDX: -1,
      
      hasAlarm: false,
      redmanAnalysis: false,
      
      volumeSliderOpen: false,
      muted: false,
      volume: 100,
      
      shutdownMessageOpen: false,
      shutdownIDX: -1,
      
      socket: io('http://toco1.demo.medivelopment.de:8091', {reconnectionAttempts: 4}),
      scenario: useScenarioStore(),
      api: new DatabaseService()
    }
  },
  async mounted() {
	console.log("Name is: " + this.scenario.name);
	this.api.bindEvents();
    
  	// update refs
	this.upperCanvas = this.$refs.uppergraphCanvas as HTMLCanvasElement;
  this.uppergraphCanvasGrid = this.$refs.uppergraphCanvasGrid as HTMLCanvasElement;

	this.lowerCanvas = this.$refs.lowergraphCanvas as HTMLCanvasElement;
  this.lowergraphCanvasGrid = this.$refs.lowergraphCanvasGrid as HTMLCanvasElement;
  	
  	// wait for the HTMLElement to be displayed
    let clientWidthIDX = setInterval(() => {
    
    	// wait for next vue tick
    	this.$nextTick(() => {
    		// check if we have a clientWidth
	    	if ((this.$refs.graphcontainer as HTMLElement).clientWidth != 0) {
	    		clearInterval(clientWidthIDX);
	    		
	    		console.log((this.$refs.graphcontainer as HTMLElement).clientWidth);
	    		this.resizeCanvas();
	    		
		    	// setup grid
			    this.setupGrid();
	    	}
	    });
    }, 200);
    
    this.dateString = moment().format('DD.MM.YYYY');
    
    setInterval(() => {
      this.timeString = moment().format('HH:mm');
      
      // TEST
      // hyper annoying
      //console.log(this.scenario.name);
    }, 1000);
    
    SoundManager.setVolume(this.volume);
    
    // make the graph responsive
    window.addEventListener('resize', () => {
      // resize
      this.resizeCanvas();

      // setup grid
        this.setupGrid();
    });
    
    // socket connection
    let pingIDX = -1;
    this.socket.on('connect', () => {
      console.log('socket.io: connected');
      pingIDX = setInterval(() => {
        this.socket.emit('ping', 'ping');
      }, 5000);
    });
    this.socket.on('disconnect', () => {
      console.log('socket.io: disconnect');
      clearInterval(pingIDX);
    });
    
    this.socket.on('command', (msg :string) => {
        console.log('command', msg);
        switch (msg) {
          case 'start':
            this.startDemo();
          break;
          
          case 'stop':
            this.startDemo();
          break;

          case 'shutdown':
            this.startShutdown();
          break;

          case 'contraction':
            console.log('contraction');
            this.demoData();
          break;

          case 'jump':
            console.log('jump fast forward by 5 minutes');
            this.fastForwardGraph();
          break;

          default:
            // message is json
            try {
              let data = JSON.parse(msg);
              console.log(data);

              if (typeof data.graph !== 'undefined') {
                /*
                ctgFHRNextGraphData = data.graph.fhr;
                ctgUCNextData = data.graph.uc;
                //*/
                

                const fhr = typeof data.graph.fhr !== 'undefined' ? data.graph.fhr : false;
                const uc = typeof data.graph.uc !== 'undefined' ? data.graph.uc : false;

                console.log("loading demo data from json", data.graph.file, fhr, uc);

                this.demoData(data.graph.file, fhr, uc);
                /*
{"graph": {"file": "2024_5619-6191_suc_mvar.json"}}
{"graph": {"file": "2010_10080-10595_muc_mvar.json"}}
                */
              }
            } catch (e) {
              console.log('error parsing json', e);
            }
        }
    });

    // load demo data
    await this.demoData();

    // load FHR baseline data
    await this.loadData('1213_fhr_baseline.json', 'fhr', false);
  },
  watch: {
  	volume(newVolume) {
  		SoundManager.setVolume(newVolume);
  	},
  	fhr(fhr) {
  		if (fhr > 190) {
  			SoundManager.play('alarm');
  			this.hasAlarm = true;
  		} else if (fhr < 110) {
  			SoundManager.play('alarm');
  			this.hasAlarm = true;
  		} else {
  			this.hasAlarm = false;
  		}
  	}
  },
  methods: {
  	resizeCanvas() {
  		let w = (this.$refs.graphcontainer as HTMLElement).clientWidth;
  		let h = (this.$refs.gridcontainer as HTMLElement).clientHeight;
  		
  		this.upperCanvas.width = w;
  		this.upperCanvas.height = h * 0.7;

      this.uppergraphCanvasGrid.width = w;
      this.uppergraphCanvasGrid.height = h * 0.7;

  		
  		this.lowerCanvas.width = w;
  		this.lowerCanvas.height = h * 0.3;

      this.lowergraphCanvasGrid.width = w;
      this.lowergraphCanvasGrid.height = h * 0.3;
  		
  		this.fr1 = w / 10;
  		this.boxSize = this.lowerCanvas.height / 5; // / 5 for proper spacing
  		this.blockSize = this.boxSize/20;
  		this.blockUCSize = this.boxSize/25;
  		
  		this.drawSize = this.boxSize/60;
  		
  		console.log(this.fr1, this.boxSize);
  	},
    drawBoard(context :CanvasRenderingContext2D, p :number, step :number, lineWidth = 0.5, offsetX = 0, offsetY = 0, startYOffset = 0, maxBlocks = 0){
	/* document.documentElement.clientHeight um an die vH zu kommen */
      // Box width
      let bw = context.canvas.width;
      // Box height
      let bh = context.canvas.height;
      
      //
      bh = Math.floor(bh/this.boxSize) * this.boxSize;
      
      if (maxBlocks > 0) {
      	bh = maxBlocks * step;
      }

      //context.translate(30, 20);
      //*
      // vertical lines
      for (let x = 0; x <= bw; x += step) {
          //context.moveTo(0.5 + x + p, p);
          //context.lineTo(0.5 + x + p, bh + p);

        context.moveTo(x + p + offsetX, p + offsetY);
        context.lineTo(x + p + offsetX, bh + p + offsetY);
      }
      //*/

      // horizontal lines
      for (let x = 0 - startYOffset; x <= bh; x += step) {
          //context.moveTo(p, 0.5 + x + p);
          //context.lineTo(bw + p, 0.5 + x + p);
        
        context.moveTo(p + offsetX, x + p + offsetY);
        context.lineTo(bw + p + offsetX, x + p + offsetY);
      }
      context.strokeStyle = "#363636";
      context.lineWidth = lineWidth;
      context.stroke();

      //context.translate(-30, -20);
    },
    drawScale(context :CanvasRenderingContext2D, from = 200, to = 60, step = 20, offsetY = 20, drawStep = 40) {
      // draw text
      //context.translate(10, 0);
      context.font = `1.3em Arial`;
      context.fillStyle = '#ffffff';
      context.textAlign = 'right';

      let offset = offsetY + 20 + 6; // 6: 1/2 font size
      offset = offsetY + drawStep/2;
      let count = 0;
      //*
      for (let top = from; top >= to; top -= step) {
        let x = 5;
        if (top < 100) {
          x = 10;
        }
        x = this.fr1/2;
        
        context.fillText(top.toString(), x, offset + count * drawStep);
        count++;
      }
      //*/

      /*
      for (let i = 0; i < 8; i++) {
        let text = 200 - i*20;
        let x = 5;
        if (text < 100) {
          x = 10
        }
        context.fillText(text, x, offset + i * 40);
      }//*/
    },
    drawTimes(context :CanvasRenderingContext2D, step :number) {
		context.font = `12px Arial`;
		context.fillStyle = '#ffffff';
		context.textAlign = 'left';
		
    	let mom = moment();
    	
    	for (let i = 0; i < 5; i++) {
    		context.fillText(String.fromCharCode(8595)+' '+mom.format('HH:mm'), this.fr1/2 + 10 + i*5*step, this.fr1/2);
    		mom.add(5, 'm');
    	}
    	
    	let bw = context.canvas.width;
      	let bh = context.canvas.height;
      	
    	context.textAlign = 'center';
    	context.fillText('1cm/min', bw/2, bh - step/2);
    },
    setupGrid() {
      let context = this.getCanvasRenderingContext2D(this.uppergraphCanvasGrid);
      
      console.log(this.uppergraphCanvasGrid.clientWidth);

      // big grid
      //this.drawBoard(context, 10, 40, 1.5, 20, 10, 20);// last working version
      // drawBoard(context :CanvasRenderingContext2D, p :number, step :number, lineWidth = 0.5, offsetX = 0, offsetY = 0, startYOffset = 0){
      this.drawBoard(context, 10, this.boxSize, 1.5, this.fr1/2, this.fr1/2, 0, 9);

      // small grid
      //this.drawBoard(context, 5, 5, 0.25, 25, 15);// last working version
      this.drawBoard(context, 5, this.boxSize/10, 0.25, this.fr1/2 + this.boxSize/10, this.fr1/2 + this.boxSize/10, 0, 90);

      // draw scale
      //this.drawScale(context);
      this.drawScale(context, 220, 40, 20, this.fr1/2, this.boxSize);
      
      this.drawTimes(context, this.boxSize);

      let ctx = this.getCanvasRenderingContext2D(this.lowergraphCanvasGrid);//this.lowerCanvas.getContext("2d");
      
      // lower canvas big grid
      this.drawBoard(ctx, 10, this.boxSize, 1.5, this.fr1/2, 0, 0, 4);
      
      // lower canvas small grid
      this.drawBoard(ctx, 5, this.boxSize/10, 0.25, this.fr1/2 + this.boxSize/10, this.boxSize/10, 0, 40);
      
      this.drawScale(ctx, 100, 0, 25, 0, this.boxSize);
      
      //this.drawBoard(ctx, 10, 30, 1.5, 20, 10, 0);
      //this.drawBoard(ctx, 5, 5, 0.25, 25, 15);
      //this.drawScale(ctx, 100, 0, 25, 0, 30);
    },
    drawFHR(ctx :CanvasRenderingContext2D, i :number, fhr :number, offsetX :number, offsetY :number, skipdraw = true) {
   		let p = 10;
    	if (fhr === 0) {
            // draw the path
            ctx.stroke();

            // begin a new one
            ctx.beginPath();
          } else {
            fhr /= 100;
            
            let fhrCorrected = (fhr - 40) * this.blockSize;
            
            let y = offsetY + p + 9 * this.boxSize - fhrCorrected;
            let x = offsetX + p + i/250/4*this.drawSize;

            ctx.lineTo(x, y);

            if (!skipdraw) {
              ctx.stroke();
            }
            
          }
    },
    drawUC(ctx :CanvasRenderingContext2D, i :number, value :number, offsetX :number, offsetY :number, skipdraw = true) {
   		let p = 10;
   		if (value === 0) {
	        // draw the path
	        ctx.stroke();
	
	        // begin a new one
	        ctx.beginPath();
	     } else {
	        value /= 100;
	
	        let valueCorrected = value * this.blockUCSize;
	        
	        let y = offsetY + p + 4 * this.boxSize - valueCorrected;
	        let x = offsetX + p + i/250/4*this.drawSize;
	
	        ctx.lineTo(x, y);
	        if (!skipdraw) {
              ctx.stroke();
            }
         }
    },
    async walkingGraph() {
      if (this.walkingGraphIDX !== -1) {
        clearInterval(this.walkingGraphIDX);
      }

      console.log('walkingGraph started');

      this.walkingGraphIDX = setInterval(() => {this.walkingGraphInternal()}, 250);
    },
    walkingGraphInternal(skipdraw = false) {
      // array check (data pointer)
      if (this.walkingGraphDatapointer >= ctgBaseFHRGraphData.length) {
        this.walkingGraphDatapointer = 0;
        console.log('end of data of ctgBaseFHRGraphData, start from beginning');
      }

      if (this.walkingGraphDatapointerUC >= ctgBaseUCData.length) {
        this.walkingGraphDatapointerUC = 0;
        console.log('end of data of ctgBaseUCData, start from beginning');
      }

      // check if there's new data to plot
      let fhrDp = 0;
      let ucDp = 0;
      if (ctgFHRNextGraphData.length !== 0) {
        // get first element
        fhrDp = ctgFHRNextGraphData.shift();
        //ucDp = ctgUCNextData.shift();

        //ctgGraphData.push([fhrDp, ucDp]);

        // and draw
        /*
        if (!skipdraw) {
          this.drawGraph();
        }
        //*/

        if (ctgFHRNextGraphData.length === 1) {
          // end of data
          console.log('end of data of ctgFHRNextGraphData');
        }
      } else {
        fhrDp = ctgBaseFHRGraphData[this.walkingGraphDatapointer];
      }

      if (ctgUCNextData.length !== 0) {
        ucDp = ctgUCNextData.shift();

        if (ctgUCNextData.length === 1) {
          // end of data
          console.log('end of data of ctgUCNextData');
        }
      } else {
        ucDp = ctgBaseUCData[this.walkingGraphDatapointerUC];
      }
      

      // get data
      //fhrDp = ctgBaseFHRGraphData[this.walkingGraphDatapointer];
      //ucDp = ctgBaseUCData[this.walkingGraphDatapointerUC];

      // push to graph data
      ctgGraphData.push([fhrDp, ucDp]);
      //console.log(ctgGraphData);

      //
      this.walkingGraphDatapointer++;
      this.walkingGraphDatapointerUC++;

      // and draw
      if (!skipdraw) {
        this.drawGraph();
      }
    },
    fastForwardGraph() {
      // pause (this should be a function not duplicated code)
      clearInterval(this.demoIDX);
      clearInterval(this.fHeartbeatIDX);
      clearInterval(this.walkingGraphIDX);
      
      this.demoIsPlaying = false;
      SoundManager.stopAll();

      let target = 5 * 60 * 4; // 5 minutes
      console.time('fastForwardGraph');
      for (let i = 0; i < target; i++) {
        this.walkingGraphInternal(true);
      }

      console.log('fastForwardGraph done');
      console.timeEnd('fastForwardGraph');
      
      // start graph again
      this.startDemo();
    },
    async drawGraph() {
      let ctx = this.getCanvasRenderingContext2D(this.upperCanvas);
      let ctxUC = this.getCanvasRenderingContext2D(this.lowerCanvas);

      let fhrOffset = -2000;

      //ctx.translate(10, 0);
      ctx.clearRect(0, 0, this.upperCanvas.width, this.upperCanvas.height);
      ctx.beginPath();
      ctx.strokeStyle = '#ffc409';
      ctx.lineWidth = 1;

      //ctxUC.translate(10, 0);
      ctxUC.clearRect(0, 0, this.lowerCanvas.width, this.lowerCanvas.height);
      ctxUC.beginPath();
      ctxUC.strokeStyle = '#e326b4';
      ctxUC.lineWidth = 1;
      let offsetX = this.fr1/2;

      let offsetYfhr = this.fr1/2;

      let fhr = 0;
      let uc = 0;

      // iterate over data
      for(let i = 0; i < ctgGraphData.length; i++) {
        let dp = ctgGraphData[i];
        //console.log(dp);

        let x = i*250;

        let skipdraw = true;
        if (i === ctgGraphData.length - 1) {
          skipdraw = false;
        }

        if (dp[0] >= 0) {
          fhr = dp[0];

          if (fhr > 0) {
            fhr += fhrOffset;
          }

          
          this.drawFHR(ctx, x, fhr, offsetX, offsetYfhr, skipdraw);
        } else {
          console.log(`fhr missing on datapoint ${i}`);
        }

        if (dp[1] >= 0) {
          uc = dp[1];

          // add baseline offset to UC
          uc += baselineOffset.value * 100;

          
          this.drawUC(ctxUC, x, uc, offsetX, 0, skipdraw);
        } else {
          console.log(`UC missing on datapoint ${i}`);
        }
      }

      // 
      this.fhr = Number((fhr/100).toFixed(0));
      this.toco = Number((uc/100).toFixed(0));
    },
    async demoData(file = '1001.json', fhr = true, uc = true) {
      /*
      let ctgBaseFHRGraphData :any[] = [];
      let ctgFHRNextGraphData :any[] = [];

      let ctgBaseUCData :any[] = [];
      let ctgUCNextData :any[] = [];
      //*/
      /*
      ctgBaseFHRGraphData = [];
      ctgBaseUCData = [];
      //*/

      console.log('loading demo data from json /assets/data/'+ file);
      await fetch('/assets/data/'+file)
      .then(r => r.json())
      .then(data => {
        //console.log(data);


        // get the first 1000 data points
        if (ctgBaseFHRGraphData.length === 0) {
          for (let i = 0; i < 200; i++) {
            let dp = data[i];
            //console.log(dp);

            if (typeof dp[0] !== 'undefined') {
              ctgBaseFHRGraphData.push(dp[0]);
            }

            if (typeof dp[1] !== 'undefined') {
              ctgBaseUCData.push(dp[1]);
            }
          }
        } else {
          // hacky hack... this is only for the demo
          if (file === '1001.json') {
            for (let i = 800; i < 1400; i++) {
              let dp = data[i];
              //console.log(dp);

              if (typeof dp[0] !== 'undefined') {
                ctgFHRNextGraphData.push(dp[0]);
              }

              if (typeof dp[1] !== 'undefined') {
                ctgUCNextData.push(dp[1]);
              }
            }
          } else {
            console.log(fhr, uc)
            for (let dp of data) {
              if (fhr && typeof dp[0] !== 'undefined') {
                ctgFHRNextGraphData.push(dp[0]);
              }

              if (uc && typeof dp[1] !== 'undefined') {
                ctgUCNextData.push(dp[1]);
              }
            }
          }
        }
      });
    },
    async loadData(file :string, target = 'fhr', next = true) {
      let data = await fetch('/assets/data/'+file).then(r => r.json());

      for (let dp of data) {

        if (target === 'fhr') {
          if (next) {
            ctgFHRNextGraphData.push(dp);
          } else {
            ctgBaseFHRGraphData.push(dp);
          }
        } else {
          if (next) {
            ctgUCNextData.push(dp);
          } else {
            ctgBaseUCData.push(dp);
          }
        }
      }
    },
    async demoGraph() {
      let ctx = this.getCanvasRenderingContext2D(this.upperCanvas);
      let ctxUC = this.getCanvasRenderingContext2D(this.lowerCanvas);

      let data = await fetch('/assets/data/1001.json').then(r => r.json());
      //let data = this.scenario.ctg.ctg;
      let fhrOffset = -2000;

      //ctx.translate(10, 0);
      ctx.beginPath();
      ctx.strokeStyle = '#ffc409';
      ctx.lineWidth = 1;

      //ctxUC.translate(10, 0);
      ctxUC.beginPath();
      ctxUC.strokeStyle = '#e326b4';
      ctxUC.lineWidth = 1;
      let offsetX = this.fr1/2;

      let offsetYfhr = this.fr1/2;

      let i = 0;
      
      this.demoIDX = setInterval(() => {
        let dp = data[i];
        if (typeof dp === 'undefined') {
          // ahm end of data
          clearInterval(this.demoIDX);
          return;
        }
        
        let x = i*250;
        
        if (dp[0] >= 0) {
         	let fhr = dp[0];
         	
         	if (fhr > 0) {
            	fhr += fhrOffset;
           	}
         	
         	this.fhr = Number((fhr/100).toFixed(0));
         	this.drawFHR(ctx, x, fhr, offsetX, offsetYfhr);
        } else {
          console.log(`fhr missing on datapoint ${i}`);
        }

        if (dp[1] >= 0) {
          let value = dp[1];

          // add baseline offset to UC
          value += baselineOffset.value * 100;
           	
          this.toco = Number((value/100).toFixed(0));
          this.drawUC(ctxUC, x, value, offsetX, 0);
        } else {
          console.log(`UC missing on datapoint ${i}`);
        }

        i++;

        if (i > 4799750) {
          clearInterval(this.demoIDX);
        }
      }, 250);
    },
    async demoGraphLegacy() {
      let ctx = this.getCanvasRenderingContext2D(this.upperCanvas);
      let ctxUC = this.getCanvasRenderingContext2D(this.lowerCanvas);

      let data = await fetch('/assets/data/1001_v2.json').then(r => r.json());

      //ctx.translate(10, 0);
      ctx.beginPath();
      ctx.strokeStyle = '#ffc409';
      ctx.lineWidth = 1;

      //ctxUC.translate(10, 0);
      ctxUC.beginPath();
      ctxUC.strokeStyle = '#e326b4';
      ctxUC.lineWidth = 1;
      let offsetX = this.fr1/2;

      let offsetYfhr = this.fr1/2;

      let i = 0;
      
      this.demoIDX = setInterval(() => {
        if (typeof data[i].fhr !== 'undefined') {
         	let fhr = data[i].fhr;
         	this.fhr = Number((fhr/100).toFixed(0));
         	this.drawFHR(ctx, i, fhr, offsetX, offsetYfhr);
        } else {
          console.log(`fhr missing on datapoint ${i}`);
        }

        if (typeof data[i].uc !== 'undefined') {
          let value = data[i].uc;
          this.toco = Number((value/100).toFixed(0));
          this.drawUC(ctxUC, i, value, offsetX, 0);
        } else {
          console.log(`UC missing on datapoint ${i}`);
        }

        i+=250;

        if (i > 4799750) {
          clearInterval(this.demoIDX);
        }
      }, 250);
    },
    playFHeartbeat() {
      clearInterval(this.fHeartbeatIDX);
      
      const timeout = (60/this.fhr)*1000;
      const fhr = this.fhr;
      
      this.fHeartbeatIDX = setInterval(() => {
      	// play sound
      	SoundManager.play('f95_300ms');
      	
      	// check if there's an update
      	if (this.fhr !== fhr) {
          this.playFHeartbeat();
        }
      }, timeout);
    },
    getCanvasRenderingContext2D(canvas: HTMLCanvasElement): CanvasRenderingContext2D {
	    const context = canvas.getContext('2d');
	
	    if (context === null) {
	        throw new Error('This browser does not support 2-dimensional canvas rendering contexts.');
	    }
	
	    return context;
	},
	startDemo() {
	    if (!this.demoIsPlaying) {
	    	// start demo
	    	//this.demoGraph();
        
        this.walkingGraph();
	    	
	    	this.demoIsPlaying = true;
	    	SoundManager.play('whalestyle');
	    	this.playFHeartbeat();
	    } else {
	    	clearInterval(this.demoIDX);
	    	clearInterval(this.fHeartbeatIDX);
        clearInterval(this.walkingGraphIDX);
	    	
	    	this.demoIsPlaying = false;
	    	SoundManager.stopAll();
	    }
	},
	volumeChangeEvent(volume :number) {
		this.volume = volume;
	},
	mute() {
    // close volume modal
    this.volumeSliderOpen = false;

    // toggle mute
		this.muted = !this.muted;
		
    // update sound manager
		SoundManager.setMute(this.muted);
	},
	volumeModalUp() {
		if (!this.volumeSliderOpen) {
			this.volumeSliderOpen = true;
		} else if (this.volume < 100) {
			this.volume += 10;
		}
	},
	volumeModalDn() {
		if (!this.volumeSliderOpen) {
			this.volumeSliderOpen = true;
		} else if (this.volume > 0) {
			this.volume -= 10;
		}
	},
	  async redman() {
      this.redmanAnalysis = !this.redmanAnalysis;

      if (!this.redmanAnalysis) {
        return;
      }
      const toast = await toastController.create({
        message: 'Dawes Redman Analyse gestartet',
        position: 'bottom',
        color: 'light',
        cssClass: 'text-center',
        duration: 2000,
      });

      toast.present();
	  },
  async moveBaseline() {
    // present toast
    const toast = await toastController.create({
      message: 'Analysing Baseline',
      position: 'bottom',
      color: 'light',
      cssClass: 'text-center',
      duration: 2000,
    });

    toast.present();

    // get current uc value
    const uc = this.toco;

    // get diff between current uc and baseline
    const diff = Math.abs(uc - Math.floor(Math.random() * 4));

    // calculate step to reach in 1.5s
    const step = diff / 15;

    // log to console
    console.log('uc:', uc, 'baseline:', baselineOffset.value, 'diff:', diff, 'step:', step);

    // move baseline
    const moveIdx = setInterval(() => {
      baselineOffset.value -= step;

      // check if we reached the diff
      if (Math.abs(uc - baselineOffset.value) >= diff) {
        clearInterval(moveIdx);
      }
    }, 100);
  },
	async startShutdown() {
		toastController.create({
			header: 'Ausschalten?',
			message: 'Das Gerät schaltet sich in 5s aus',
			position: 'middle',
			duration: 5000,
			buttons: [
			  	{
			          text: 'Abbrechen',
			          role: 'abort',
			          icon: this.closeCircleOutline,
			          cssClass: 'border-left'
			    },
			    {
			          text: 'Ausschalten',
			          role: 'shutdown',
			          side: 'start',
			          icon: this.powerOutline,
			          cssClass: 'border-right'
			    }],
			color: 'danger',
			cssClass: 'shutdown-toast'
		}).then((toast)=> {
			toast.present();
			//console.log('toast.duration: ',toast.duration);
			
			toast.onDidDismiss().then((ev) => {
				const { role } = ev;
				
				console.log(role);
				
				switch(role) {
					case 'abort': break;
					default:
						// end the simulation
						// TODO do a proper "shutdown" here
						location.reload();
				}
			});
		});
	},
  }
});
