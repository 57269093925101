import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e563af68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "volumeslider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_range = _resolveComponent("ion-range")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_range, {
      ticks: true,
      snaps: true,
      min: 0,
      max: 100,
      step: 10,
      value: _ctx.volume,
      onIonChange: _ctx.volumeChangeEvent,
      "aria-label": "Volume"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_icon, {
          slot: "start",
          icon: _ctx.volumeLowOutline
        }, null, 8, ["icon"]),
        _createVNode(_component_ion_icon, {
          slot: "end",
          icon: _ctx.volumeHighOutline
        }, null, 8, ["icon"])
      ]),
      _: 1
    }, 8, ["value", "onIonChange"])
  ], 512)), [
    [_vShow, _ctx.open]
  ])
}