import { ref, computed } from "vue"
import { defineStore } from "pinia";

export const useScenarioStore = defineStore('scenario', () => {
  const name = ref('')
  const ctg = ref({})

  function setName(newName: string) {
    name.value = newName;
  }
  
  function setCtg(newCtg: any) {
    ctg.value = newCtg;
  }

  return { name, ctg, setName, setCtg }
})
