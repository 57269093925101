
import { defineComponent } from 'vue';
import { IonRange, IonIcon } from '@ionic/vue';

import {
	volumeHighOutline,
	volumeLowOutline,
} from 'ionicons/icons';

export default defineComponent({
	name: 'VolumeSlider',
	components: {
		IonRange,
		IonIcon
	},
	props: ['open', 'volume'],
	setup() {
		return {
			volumeHighOutline,
			volumeLowOutline,
		};
	},
	data() {
		return {
			displayIDX: -1
		};
	},
	watch: {
		open(newValue) {
			if (newValue) {
				this.resetCloseTimer();
			}
		},
		volume() {
			this.resetCloseTimer();
		}
	},
	methods: {
		resetCloseTimer() {
			clearTimeout(this.displayIDX);
			
			this.displayIDX = setTimeout(() => {
			console.log('closing volumeslider');
				this.$emit('closeEvent');
			}, 3000);
		},
		volumeChangeEvent({ detail } :CustomEvent){
			// detail.value
			// emit
			this.$emit('volumeChangeEvent', detail.value);
		}
	}
});
