import Strapi from "strapi-sdk-js"
import io from "socket.io-client"
import { useScenarioStore } from "@/stores/scenario"

export class DatabaseService {
	private _strapi = new Strapi({
		// Options
	})
	// connect stores
	private _scenario = useScenarioStore();

	/*
		constructor() {
		}
	*/

	get strapi(): Strapi {
		return this._strapi;
	}

	get scenario(): any {
		return this._scenario;
	}

	public async bindEvents(): Promise<any> {
    	/*
    	this.getScenario();

		// URL to your strapi instance
		const SERVER_URL = 'http://localhost:8091';

		// connect the socket
		const socket = io(SERVER_URL);

		//  wait until socket connects before adding event listeners
		socket.on('connect', () => {
			socket.on('scenario:create', (data) => {
				console.log('scenario created!');
				console.log(data);
			});
			socket.on('scenario:update', (data) => {
				console.log('scenario updated!');
				console.log(data);
				//this.scenario.setName(data.data.attributes.name);
				//this.scenario.setCtg(data.data.attributes.ctg.data.attributes);
			});
			socket.on('scenario:delete', (data) => {
				console.log('scenario deleted!');
				console.log(data);
			});
		});
		//*/
	}

	public async getScenario(): Promise<any> {
		const response = await this.strapi.request("get", "/scenario?populate=*");
		console.log(response);
		//this.scenario.setName(response.data.attributes.name);
		//this.scenario.setCtg(response.data.attributes.ctg.data.attributes);
		return response;
	}
}

export default DatabaseService;