class SoundManager {
	private static _instance: SoundManager;

	private sounds = new Map<string, HTMLAudioElement>();

	constructor() {
		//const nibp = new Audio('/assets/sounds/nibp.wav');
		//nibp.volume = 0.2;

		this.sounds.set('pleth100', new Audio('/assets/sounds/f19.wav'));
		this.sounds.set('F13_300ms', new Audio('/assets/sounds/F13_300ms.wav'));
		this.sounds.set('f95_300ms', new Audio('/assets/sounds/f95_300ms.wav'));
		this.sounds.set('alarm', new Audio('/assets/sounds/alm_high.wav'));
		
		const whale = new Audio('/assets/sounds/underwater-ambiencewav-14428.mp3');
		whale.loop = true;
		this.sounds.set('whalestyle', whale);
	}

	public static getInstance() {
		if (this._instance == null) {
			this._instance = new SoundManager();
		}

		return this._instance;
	}

	play(sound :string) {
		if (this.sounds.has(sound)) {
			this.sounds.get(sound)?.play();
		}
	}

	stop(sound :string) {
		const media = this.sounds.get(sound);
		if (typeof media !== 'undefined') {
			media.pause();
			media.currentTime = 0;
		}
	}
	
	stopAll() {
		this.sounds.forEach(media => {
			media.pause();
			media.currentTime = 0;
		});
	}

	setVolume(volume :number) {
		this.sounds.forEach((v,k) => {
			if (k !== 'alarm') {
				v.volume = volume/100;
				console.log(`${k} new volume: ${volume}`);
			}
		});
	}
	
	setMute(muted :boolean) {
		const media = this.sounds.get('alarm');
		if (typeof media !== 'undefined') {
			media.muted = muted;
		}
	}
}

export default SoundManager.getInstance();